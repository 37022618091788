<template>
  <div class="freshdeskCustomerTicketAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ getTitle() }}
        </h4>
        <template v-if="subject == 'add'">
          <hr class="m-0 mb-3">
          <label>{{ $t('email') }}</label>
          <input
            v-model="addEmail"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('subject') }]"
            type="text"
          >
        </template>
        <template v-if="subject == 'add'">
          <hr class="m-0 mb-3">
          <label>{{ $t('phone') }}</label>
          <input
            v-model="addPhone"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('subject') }]"
            type="text"
          >
        </template>
        <template v-if="subject == 'add'">
          <hr class="m-0 mb-3">
          <label>{{ $t('contactNotificationMedia') }}</label>
          <select
            v-model="addNotificationMedia"
            class="form-control"
          >
            <option
              v-for="(notificationMedia, i) in notificationMedia"
              :key="`notificationMedia${i}`"
            >
              {{ notificationMedia }}
            </option>
          </select>
        </template>
        <hr class="m-0 mb-3">
        <label>{{ $t('freshdesk.subject') }}</label>
        <input
          v-model="ticket.subject"
          v-focus
          disabled
          :class="['form-control', { 'is-invalid': $validator.errors.has('subject') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('description') }}</label>
        <textarea
          v-model="ticket.description"
          :class="['form-control', { 'is-invalid': $validator.errors.has('description') }]"
          rows="5"
        />
        <label>{{ $t('severity') }}</label>
        <select
          v-model="ticket.priority"
          :class="['form-control', { 'is-invalid': $validator.errors.has('priority') }]"
        >
          <option
            v-for="(prio, i) in priorities"
            :key="`prio${i}`"
            :value="prio.value"
          >
            {{ prio.name }}
          </option>
        </select>
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!ticket.subject || !ticket.description"
        @click.prevent="getUserContact()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('freshdesk.create') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';
import historyAdapter from '@/mixins/historyAdapter.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "FreshdeskCustomerTicketAdd",
  mixins: [
    errorMixin,
    historyAdapter,
    authenticationMixin
  ],
  props: {
    contact: {
      type: Object,
      required: false,
      default () {
        return null
      }
    },
    installationId: {
      type: String,
      required: true
    },
    subject: {
      type: String,
      required: true
    },
    jiraUrl: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      addEmail: null,
      addPhone: null,
      addNotificationMedia: 'SMS and Email',
      notificationMedia: ['SMS and Email', 'SMS', 'Email', 'None'],
      ticket: {
        priority: 1,
        subject: this.getSubject(),
        description: this.subject == 'add' ? this.jiraUrl + '\n\r' : null
      },
      priorities: [ 
        {
          name: 'Low',
          value: 1
        },
        {
          name: 'Medium',
          value: 2
        },
        {
          name: 'High',
          value: 3
        },
        {
          name: 'Urgent',
          value: 4
        }
      ]
    }
  },
  methods: {
    getTitle () {
      if (this.subject == "edit") {
        return this.$t('editContact')
      }
      else if(this.subject == "delete") {
        return this.$t('deleteContact')
      }
      else if(this.subject == "remove") {
        return this.$t('removeContactFromInstallation')
      }
      else if(this.subject == "add") {
        return this.$t('addContact')
      }
      return "";
    },
    getSubject () {
      if(this.subject == "add") {
        return this.getTitle() + ' to ' + this.installationId;
      }
      else if(this.subject == "edit") {
        return this.getTitle() + ' ' + this.contact.id + ' on ' + this.installationId;
      }
      else if(this.subject == "remove") {
        var text = this.getTitle();
        return text.replace(' e', ' ' + this.contact.id + ' e').replace(' f', ' ' + this.contact.id + ' f').replace('Installation', '')  + this.installationId;
      }
      return this.getTitle() + ' ' + this.contact.id;
    },
    async getUserContact () {
      var user = this.authenticationGetUser();
       await this.axios.get(`/Issue/GetContactByEmail?email=${user.details.profile.email}`)
        .then((response) => {
          if(response && response.data) {
              var userContact = response.data
              this.createFreshdeskTicket(userContact);
          }
          else {
            this.$snotify.error('User not found'); 
          }
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    async createFreshdeskTicket (userContact) {
      let postObject = {
        'contact': userContact,
        'ticket': {
          'subject': this.ticket.subject,
          'description': this.ticket.description,
          'priority': this.ticket.priority,
          'email': userContact.email,
          'source': 2,
          'tags': [
            this.installationId
          ],
          'phone': userContact.phone,
          'unique_external_id': userContact.id
        }
      };
      if(this.subject == 'add') {
        var desc = '';

        if(this.addEmail) {
          desc += `Email: ${this.addEmail} <br>`;
        }
        if(this.addPhone) {
          desc += `Phone: ${this.addPhone} <br>`;
        }
        if(this.addNotificationMedia) {
          desc += `Notification Media: ${this.addNotificationMedia} <br>`;
        }

        postObject.ticket.description = desc + '<br>' + postObject.ticket.description;
      }

      await this.axios.post('/Freshdesk/CreateTicket', postObject)
        .then((response) => {
          if (response) {
            this.$snotify.success(this.$t('freshdesk.createdSuccessfully'));
            this.createReport(postObject, response.data.id);
            this.$emit("close");
            this.error_clear();  
          }
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    createReport (postObject, id) {
      let text = "Freshdesk Ticket created #" + id + " \n ------------------ \n Subject: " + postObject.ticket.subject;
      let zabbixRequest = this.sendHistoryComment(this.installationId, text, 'Freshdesk', 'Info');
      zabbixRequest.then(() => {
        this.$snotify.success("The report was successfully sent.");
        this.$eventBus.$emit('refreshHistory');
        this.$eventBus.$emit('refreshProblems');
       })
    }
  }
}
</script>

<style scoped>
.freshdeskCustomerTicketAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
